import React, { useContext, useEffect, useRef } from 'react';
import Logo from './../components/Logo/Logo.jsx';
import ScrollDown from './../components/ScrollDown/ScrollDown.jsx'
import './Styles.css';
//import scrollDown from './../img/scroll-down.svg';
// language
import { langContext } from '../context/LangContext.js';
// import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import './../components/Buttons/Buttons.css';



const Home = ({gsap}) => {
// console.log(gsap);
 
  const language = useContext(langContext);



  return (
    <section className="container contHome" id="home" >

      <Logo className='marquita' />

      <div className="extraHome" >

        <h2 className="profession" >
          <FormattedMessage id="home.profession_l1" defaultMessage="Graphic Designer &amp;"/> <br />
          <FormattedMessage id="home.profession_l2" defaultMessage="Frontend Developer &amp;"/>          
        </h2>

        <div className="botonera" >          
          <a href={
            language.locale === 'en-US' 
              ? 'https://tenco.com.ar/download/eng/Tenconi-Daniel-eng.pdf'
              : 'https://tenco.com.ar/download/esp/Tenconi-Daniel-spa.pdf'
              } 
              download="Daniel-Tenconi" target="_blank" className="fillBtn btn_dwn" rel="noreferrer">
            <p>
              <FormattedMessage id="home.down" defaultMessage="Download CV"/>
            </p>
          </a>

          <a href="#contact" className="lineBtn btn_tlk">
            <p>
              <FormattedMessage id="home.talk" defaultMessage="Lets Talk!"/>
            </p>
          </a>

        </div>

        <a href="#about">
          <ScrollDown />
        </a>

      </div>


    </section>
  );
};

export default Home;
