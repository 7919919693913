import React, { useState } from 'react';
import LineBtn from './../components/Buttons/LineBtn.jsx';
import { FormattedMessage } from 'react-intl';
// img
import DanielTenconi from './../img/daniel-tenconi.png'

const About = () => {

const [fullBio, setFullBio] = useState(false);
const changeState =()=>{
  setFullBio(!fullBio)
}

 

  return (
    <section className='container contAbout' id='about'>
        <h2 className='section__title'>
          <FormattedMessage id="about.tit" defaultMessage="About Me" />
        </h2>

          <div className='contAbout__main' >
            <div>
              <p><FormattedMessage id="about_p1" defaultMessage="Hello! My name is Daniel Tenconi." /></p>

              <p>
                <FormattedMessage id="about_p2" defaultMessage=" I am a graphic designer and web developer with advertising orientation
                and visual communicator, I am a graphic designer and web developer
                with advertising orientation and visual communicator." />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="about_p3" defaultMessage="My main goal is to provide graphics and comunicational solutions." />
                </strong>
              </p>

              <div className={'extraBio ' + (fullBio ? 'showFull' : '')}>
                <p>
                  <FormattedMessage id="about_p4" defaultMessage="I have a degree in Graphic Design from John F. Kennedy University; my institutional and academic life has always revolved around art, design and communication.Over the years, the relationship with my profession was one of love and hate, although I am passionate about design, but the poor pay in my country has forced me to seek other ways.The passion for my profession was growing up again, thanks to perseverance, constant learning of tools" /> 
                  <strong><em><FormattedMessage id="about_tools" defaultMessage="( html5, css3, Sass, Bootstrap, javaScript, ReactJS, Express and MongoDB )" /></em></strong> <FormattedMessage id="about_p5" defaultMessage=", the expansion of my knowledge in other areas (frontend developer), and the opportunity to work in important design studios."
              />
                </p>
              
            </div>  

            <LineBtn valor={!fullBio ? (<FormattedMessage id="about.full"/>) : (<FormattedMessage id="about.less"/>)} onClick={changeState}/>
            </div>

            <img src={DanielTenconi} alt="tenco | Daniel Tenconi" className='pic__tenconi' />
          </div>
        
    </section>
  )
}

export default About