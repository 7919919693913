import React, { useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './Works.css';
import { WorksContext } from '../../context/WorksContext.js';
import Loading from '../Loading/Loading.jsx';
import { Tilt } from 'react-tilt';
import tiltOptions from './../../effects/Tilt.js'
import { FormattedMessage } from 'react-intl';
const Workmodal = () => {
  const { id } = useParams();
  // console.log(id);

  const worksContext = useContext(WorksContext);
  const worksList = worksContext.works; // ♥ Array

  const selectWork = worksList.filter((w) => w.id === id);
  // console.log(selectWork);

  const [proy, setProy] = useState(selectWork);
  // const [color, setColor] = useState(selectWork);

  const [load, setLoad] = useState(false);

  setTimeout(() => {
    setLoad(true);
  }, 600);

  //  ♠ Next Work ♠
  const nextWork = (id) => {
    setTimeout(() => {
      setLoad(true);
      const busco = worksList.indexOf(proy[0]); // busco mismo objeto que quiero encontrar (proy arroja un arreglo)
      const proximo = worksList[Number(busco) + 1];

      setProy([proximo]);

      if (proximo === worksList[worksList.length]) {
        setProy([worksList[0]]);
      }
    }, 600);
    setLoad(false);
  };

  //  ♠ Previous Work ♠
  const prevWork = () => {
    setTimeout(() => {
      setLoad(true);
      const busco = worksList.indexOf(proy[0]); // busco mismo objeto que quiero encontrar (proy arroja un arreglo)
      const anterior = worksList[Number(busco) - 1];

      setProy([anterior]);

      if (proy[0] === worksList[0]) {
        setProy([worksList[worksList.length - 1]]);
      }
    }, 600);
    setLoad(false);
  };

  // - traigo el 'id' desde usePARAMS
  return (
    <div className="modal__back">
      
      <div className="arrow__prev" onClick={() => prevWork(id)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
        </svg>
      </div>

      <div className="modal">
        {!load ? (
          <Loading />
        ) : (
          proy.map((w) => {
            return (
              <div className="modal__main" key={w.id}>
                
                <Link to="../" className="modal__close">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="times-circle"
                    className="close"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
                  </svg>
                </Link>

                <div>
                  <p className="work__cat--modal">{w.cat}</p>
                  <h3 className="work__tit--modal">{w.tit}</h3>

                  {w.url ? (
                    <a
                      href={w.url}
                      className="work__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
                      </svg>{' '}
                      visit the site
                    </a>
                  ) : null}


                  {w.color ? <br /> : null}
                  {w.color ? <h3 className="work__sub"><FormattedMessage id='modal_color' defaultMessage='The colors:'/></h3> : null}

                  


                  {w.media ? 
                  (<div className='modal__desc--with-video'>
                    <div className='modal__desc--porta-video'>
                      <video src={w.media} preload='auto' autoPlay loop poster={w.img}></video>
                    </div>

                      <div>
                        <p>{w.desc}</p>
                        <p>{w.tec}</p>
                        <p>{w.det}</p>
                      </div>
                    </div>) : (
                    <div className='modal__desc--without-video'>
                      <p>{w.desc}</p>
                      <p>{w.tec}</p>
                      <p>{w.det}</p>
                    </div>
                  )
                  }   


                  {w.design ? (

                  <div className='work__brand--design'>
                    
                    <div className='work__brand--cont'>
                      <h3 className="work__sub"><FormattedMessage id='modal_brand' defaultMessage='The brand:'/></h3>

                      <div className="work__design--brand">
                        
                            {/* <img src={w.design.brand.brand_hz} alt={w.tit}/> */}
                          <Tilt options={tiltOptions}>
                            <img src={w.design.brand.brand} alt={w.tit}/>
                          </Tilt>

                        <div>
                          <p>{w.design.desc_p1}</p>
                          <p>{w.design.desc_p2}</p>
                          <p>{w.design.desc_p3}</p>
                        </div>
                      </div>                      
                    </div>

                    <div className='work__typo--cont'>
                      <h3 className="work__sub"><FormattedMessage id='modal_typo' defaultMessage='The typography:'/></h3>

                      <div className='work__design--typo'>
                        <p>{w.design.typo.det}</p>                     
                        <img src={w.design.typo.img} alt={w.tit}/>
                      </div>
                    </div>

                    <div className='work__color--cont'>
                      <h3 className="work__sub">The colors:</h3>

                      <div className='work__design--colors'>
                        <div>
                          <p>{w.design.color.det}</p>
                        </div>

                        <div className="work__design--colors-container">
                          <div className='work__color--color'>
                            <div className='work__color--back' style={{background:`${w.design.color.color_1[0]}`}}>
                              {/* <img src={w.design.color.color_1_img} alt={w.tit}/> */}
                              <p className='color--strong'>{w.design.color.color_1[0]}</p>
                              <p>{w.design.color.color_1[1]}</p>
                              <p>{w.design.color.color_1[2]}</p>  
                            </div>                          
                          </div>

                          <div className='work__color--color' >
                            <div className='work__color--back' style={{background:`${w.design.color.color_2[0]}`}}>
                              {/* <img src={w.design.color.color_2_img} alt={w.tit}/> */}
                              <p className='color--strong'>{w.design.color.color_2[0]}</p>
                              <p>{w.design.color.color_2[1]}</p>
                              <p>{w.design.color.color_2[2]}</p>
                            </div>
                          </div>

                          <div className='work__color--color' >
                            { w.design.color.color_3
                            ?
                              (<div className='work__color--back' style={{background:`${w.design.color.color_3[0]}`}}>
                                {/* <img src={w.design.color.color_2_img} alt={w.tit}/> */}
                                <p className='color--strong'>{w.design.color.color_3[0]}</p>
                                <p>{w.design.color.color_3[1]}</p>
                                <p>{w.design.color.color_3[2]}</p>
                              </div>)
                              : null
                            }
                          </div>
                        </div>
                      </div>                      
                      
                    </div>
                    
                  </div> )
                  : null}
                  

                  
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="arrow__next" onClick={() => nextWork(id)} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
        </svg>
      </div>
    </div>
  );
};

export default Workmodal;
