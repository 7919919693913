import React from 'react';
import './BackGround.css';
import circuloCyan from './../../img/bg/circle_cyan.svg';
import circuloGreen from './../../img/bg/circle_green.svg';
import circuloOrange from './../../img/bg/circle_orange.svg';
import circuloViolet from './../../img/bg/circle_violet.svg';

import cruzCyan from './../../img/bg/cross_cyan.svg';
import cruzGreen from './../../img/bg/cross_green.svg';
import cruzPink from './../../img/bg/cross_pink.svg';
import cruzViolet from './../../img/bg/cross_violet.svg';

const BackGround = () => {
  return (
    <div className="manto">
      <img src={circuloGreen} alt="o" className="circulo_1" />

      <img src={circuloCyan} alt="o" className="circulo_2" />
      <img src={cruzCyan} alt="x" className="cruz_1" />

      <img src={circuloOrange} alt="o" className="circulo_3" />

      <img src={cruzViolet} alt="x" className="cruz_2" />
      <img src={cruzViolet} alt="x" className="cruz_3" />

      <img src={circuloViolet} alt="o" className="circulo_4" />

      <img src={cruzViolet} alt="x" className="cruz_4" />
      <img src={circuloCyan} alt="o" className="circulo_5" />

      <img src={circuloViolet} alt="o" className="circulo_6" />
      <img src={circuloGreen} alt="o" className="circulo_7" />
      <img src={cruzGreen} alt="x" className="cruz_5" />
      <img src={cruzViolet} alt="x" className="cruz_6" />

      <img src={circuloGreen} alt="o" className="circulo_8" />
      <img src={circuloViolet} alt="o" className="circulo_9" />
      <img src={circuloCyan} alt="o" className="circulo_10" />

      <img src={cruzViolet} alt="x" className="cruz_7" />
      <img src={cruzPink} alt="x" className="cruz_8" />

      <img src={circuloCyan} alt="o" className="circulo_11" />
      <img src={circuloViolet} alt="o" className="circulo_12" />
    </div>
  );
};

export default BackGround;
