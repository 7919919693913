import React from 'react';
import './Loading.css'

const Loading = () => {
  return (
    <div className='load__container'>

            <svg width="785" height="524" viewBox="0 0 785 524" fill="none" className='load'>

            <rect x="120.463" y="93.3131" width="544.32" height="272.64" fill="url(#pattern0)"/>
            <path d="M498.4 93.5L286.7 305.2L241.2 259.7H313C326.8 259.7 338 248.5 338 234.7C338 220.9 326.8 209.7 313 209.7H120.5L269 358.2C273.9 363.1 280.3 365.5 286.7 365.5C293.1 365.5 299.5 363.1 304.4 358.2L498.4 164.2L543.9 209.7H477.2C463.4 209.7 452.2 220.9 452.2 234.7C452.2 248.5 463.4 259.7 477.2 259.7H664.6L498.4 93.5Z" stroke="url(#paint0_linear_702_31)" strokeMiterlimit="10" className='load__line'/>

            <path d="M498.4 93.5L286.7 305.2L241.2 259.7H313C326.8 259.7 338 248.5 338 234.7C338 220.9 326.8 209.7 313 209.7H120.5L269 358.2C273.9 363.1 280.3 365.5 286.7 365.5C293.1 365.5 299.5 363.1 304.4 358.2L498.4 164.2L543.9 209.7H477.2C463.4 209.7 452.2 220.9 452.2 234.7C452.2 248.5 463.4 259.7 477.2 259.7H664.6L498.4 93.5Z" fill="url(#paint0_linear_702_31)" strokeMiterlimit="10" className='load__back'/>
            <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">

            <use  transform="scale(0.000881057 0.00175747)"/>
            </pattern>
            <linearGradient id="paint0_linear_702_31" x1="119.277" y1="229.439" x2="665.763" y2="229.439" gradientUnits="userSpaceOnUse">
            <stop stopColor="#80D8FF"/>
            <stop offset="1" stopColor="#AEEA00"/>
            </linearGradient>
            </defs>
            </svg>



    </div>
  )
}

export default Loading