import React from 'react'
import SwitchLang from './SwitchLang.jsx'
import './NavStyles.css'
import DotsMenu from './DotsMenu.jsx'

const Nav = () => {
  return (
    <nav>
        <div className="navBar">
        <SwitchLang/>

        <DotsMenu/>
        </div>

    </nav>
  )
}

export default Nav