import React, { useState, useContext } from 'react';
// import SideMenu from './SideMenu.jsx';
import { langContext } from './../../context/LangContext.js';
import { FormattedMessage } from 'react-intl';

const DotsMenu = () => {
  const [sideMenu, setSideMenu] = useState(false);

  const language = useContext(langContext);

  // const toggleSideBar = () => {
  //   setTimeout(() => {
  //     setSideMenu(!sideMenu);
  //   }, 1000)

  // };

  const toggleSideBar = () => {
    setSideMenu(!sideMenu);
  };

  return (
    <div className="dotMenu">
      <div onClick={toggleSideBar} className="dotsBtn">
        <span className={'normalSpan ' + (sideMenu ? 'topSpan' : '')}></span>
        <span className={'normalSpan ' + (sideMenu ? 'midSpan' : '')}></span>
        <span className={'normalSpan ' + (sideMenu ? 'botSpan' : '')}></span>
      </div>

      <p className={"menu " + (sideMenu ? 'close__menu' : '')}> {sideMenu ? (<FormattedMessage id='menu.close' defaultMessage='Close' />) : ('Menu')} </p>

      <div className={'sideAll ' + (sideMenu ? 'showSideAll' : '')}>

        <div className={'negativeSideBar ' + (sideMenu ? 'showNegativeSideBar' : '')} onClick={toggleSideBar}>
          {/* black side */}
        </div>

        {/* menu */}
        <div className={'sideMenu ' + (sideMenu ? 'showSideMenu' : '')}>
          
          <a href="#home" onClick={toggleSideBar}>
            <FormattedMessage id="menu.init" defaultMessage="home" />
          </a>

          <br />

          <a href="#about" onClick={toggleSideBar}>
            <FormattedMessage id="menu.about" defaultMessage="about me" />
          </a>

          <br />

          <a href="#skills" onClick={toggleSideBar}>
            <FormattedMessage id="menu.skills" defaultMessage="skills" />
          </a>

          <br />

          <a href="#portfolio" onClick={toggleSideBar}>
            <FormattedMessage id="menu.port" defaultMessage="portfolio" />
          </a>

          <br />

          <a href="#contact" onClick={toggleSideBar}>
            <FormattedMessage id="menu.contact" defaultMessage="contact me" />
          </a>

          <br />

          <ul className="buttonDonloadCV">
            <li>
              <a
                href={
                  language.locale === 'en-US'
                    ? 'https://tenco.com.ar/download/eng/Tenconi-Daniel-eng.pdf'
                    : 'https://tenco.com.ar/download/esp/Tenconi-Daniel-spa.pdf'
                }
                download="Daniel-Tenconi"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage
                  id="menu.donload"
                  defaultMessage="Download CV"
                />
              </a>
            </li>
          </ul>

          <ul className="icoList">
            <li>
              {/* linkedin */}
              <a
                href="https://www.linkedin.com/in/daniel-tenconi/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 448 448"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icoSideBar"
                >
                  <path
                    d="M416 0H31.9C14.3 0 0 14.5 0 32.3V415.7C0 433.5 14.3 448 31.9 448H416C433.6 448 448 433.5 448 415.7V32.3C448 14.5 433.6 0 416 0ZM135.4 384H69V170.2H135.5V384H135.4ZM102.2 141C80.9 141 63.7 123.7 63.7 102.5C63.7 81.3 80.9 64 102.2 64C123.4 64 140.7 81.3 140.7 102.5C140.7 123.8 123.5 141 102.2 141V141ZM384.3 384H317.9V280C317.9 255.2 317.4 223.3 283.4 223.3C248.8 223.3 243.5 250.3 243.5 278.2V384H177.1V170.2H240.8V199.4H241.7C250.6 182.6 272.3 164.9 304.6 164.9C371.8 164.9 384.3 209.2 384.3 266.8V384V384Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>

            <li>
              {/* git hub */}
              <a
                href="https://github.com/tenconi"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 496 484"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icoSideBar"
                >
                  <path
                    d="M165.9 389.4C165.9 391.4 163.6 393 160.7 393C157.4 393.3 155.1 391.7 155.1 389.4C155.1 387.4 157.4 385.8 160.3 385.8C163.3 385.5 165.9 387.1 165.9 389.4ZM134.8 384.9C134.1 386.9 136.1 389.2 139.1 389.8C141.7 390.8 144.7 389.8 145.3 387.8C145.9 385.8 144 383.5 141 382.6C138.4 381.9 135.5 382.9 134.8 384.9ZM179 383.2C176.1 383.9 174.1 385.8 174.4 388.1C174.7 390.1 177.3 391.4 180.3 390.7C183.2 390 185.2 388.1 184.9 386.1C184.6 384.2 181.9 382.9 179 383.2ZM244.8 0C106.1 0 0 105.3 0 244C0 354.9 69.8 449.8 169.5 483.2C182.3 485.5 186.8 477.6 186.8 471.1C186.8 464.9 186.5 430.7 186.5 409.7C186.5 409.7 116.5 424.7 101.8 379.9C101.8 379.9 90.4 350.8 74 343.3C74 343.3 51.1 327.6 75.6 327.9C75.6 327.9 100.5 329.9 114.2 353.7C136.1 392.3 172.8 381.2 187.1 374.6C189.4 358.6 195.9 347.5 203.1 340.9C147.2 334.7 90.8 326.6 90.8 230.4C90.8 202.9 98.4 189.1 114.4 171.5C111.8 165 103.3 138.2 117 103.6C137.9 97.1 186 130.6 186 130.6C206 125 227.5 122.1 248.8 122.1C270.1 122.1 291.6 125 311.6 130.6C311.6 130.6 359.7 97 380.6 103.6C394.3 138.3 385.8 165 383.2 171.5C399.2 189.2 409 203 409 230.4C409 326.9 350.1 334.6 294.2 340.9C303.4 348.8 311.2 363.8 311.2 387.3C311.2 421 310.9 462.7 310.9 470.9C310.9 477.4 315.5 485.3 328.2 483C428.2 449.8 496 354.9 496 244C496 105.3 383.5 0 244.8 0ZM97.2 344.9C95.9 345.9 96.2 348.2 97.9 350.1C99.5 351.7 101.8 352.4 103.1 351.1C104.4 350.1 104.1 347.8 102.4 345.9C100.8 344.3 98.5 343.6 97.2 344.9ZM86.4 336.8C85.7 338.1 86.7 339.7 88.7 340.7C90.3 341.7 92.3 341.4 93 340C93.7 338.7 92.7 337.1 90.7 336.1C88.7 335.5 87.1 335.8 86.4 336.8ZM118.8 372.4C117.2 373.7 117.8 376.7 120.1 378.6C122.4 380.9 125.3 381.2 126.6 379.6C127.9 378.3 127.3 375.3 125.3 373.4C123.1 371.1 120.1 370.8 118.8 372.4ZM107.4 357.7C105.8 358.7 105.8 361.3 107.4 363.6C109 365.9 111.7 366.9 113 365.9C114.6 364.6 114.6 362 113 359.7C111.6 357.4 109 356.4 107.4 357.7Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>

            <li>
              {/* whatsapp */}
              <a
                href="https://api.whatsapp.com/send?phone=34631789860&text=Hello%20Daniel!"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 448 448"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icoSideBar"
                >
                  <path
                    d="M380.9 65.1C339 23.1 283.2 0 223.9 0C101.5 0 1.9 99.6 1.9 222C1.9 261.1 12.1 299.3 31.5 333L0 448L117.7 417.1C150.1 434.8 186.6 444.1 223.8 444.1H223.9C346.2 444.1 448 344.5 448 222.1C448 162.8 422.8 107.1 380.9 65.1V65.1ZM223.9 406.7C190.7 406.7 158.2 397.8 129.9 381L123.2 377L53.4 395.3L72 327.2L67.6 320.2C49.1 290.8 39.4 256.9 39.4 222C39.4 120.3 122.2 37.5 224 37.5C273.3 37.5 319.6 56.7 354.4 91.6C389.2 126.5 410.6 172.8 410.5 222.1C410.5 323.9 325.6 406.7 223.9 406.7V406.7ZM325.1 268.5C319.6 265.7 292.3 252.3 287.2 250.5C282.1 248.6 278.4 247.7 274.7 253.3C271 258.9 260.4 271.3 257.1 275.1C253.9 278.8 250.6 279.3 245.1 276.5C212.5 260.2 191.1 247.4 169.6 210.5C163.9 200.7 175.3 201.4 185.9 180.2C187.7 176.5 186.8 173.3 185.4 170.5C184 167.7 172.9 140.4 168.3 129.3C163.8 118.5 159.2 120 155.8 119.8C152.6 119.6 148.9 119.6 145.2 119.6C141.5 119.6 135.5 121 130.4 126.5C125.3 132.1 111 145.5 111 172.8C111 200.1 130.9 226.5 133.6 230.2C136.4 233.9 172.7 289.9 228.4 314C263.6 329.2 277.4 330.5 295 327.9C305.7 326.3 327.8 314.5 332.4 301.5C337 288.5 337 277.4 335.6 275.1C334.3 272.6 330.6 271.2 325.1 268.5Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/*  {sideMenu 
      ? (
        <div className="sideAll">

          <div className="negativeSideBar" onClick={toggleSideBar}>
          </div>

          <div className="sideMenu">
            <a href="#init" onClick={toggleSideBar}>
              <FormattedMessage id="menu.init" defaultMessage="home" />
            </a>
            <br />
            <a href="#about" onClick={toggleSideBar}>
              <FormattedMessage id="menu.about" defaultMessage="about me" />
            </a>
            <br />
            <a href="#skills" onClick={toggleSideBar}>
              <FormattedMessage id="menu.skills" defaultMessage="skills" />
            </a>
            <br />
            <a href="#portfolio" onClick={toggleSideBar}>
              <FormattedMessage id="menu.port" defaultMessage="portfolio" />
            </a>
            <br />
            <a href="#contact" onClick={toggleSideBar}>
              <FormattedMessage id="menu.contact" defaultMessage="contact me" />
            </a>
            <br />
            <a href="#" onClick={toggleSideBar}>
              <FormattedMessage
                id="menu.donload"
                defaultMessage="download CV"
              />
            </a>
          </div>

        </div>
      ) 
      : null} */}
    </div>
  );
};

export default DotsMenu;
