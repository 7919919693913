import './App.css';
import Footer from './components/Footer/Footer';
import Nav from './components/Nav/Nav.jsx';
import About from './views/About';
import Contact from './views/Contact.jsx';
import Home from './views/Home.jsx';
import Portfolio from './views/Portfolio.jsx';
import Skills from './views/Skills.jsx';
import { Route, Routes, BrowserRouter as Browser } from 'react-router-dom';
import Workmodal from './components/Portfolio/Workmodal.jsx';
import BackGround from './components/BackGround/BackGround.jsx';

// ♠ gsap
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <div>
      <Browser>
        <BackGround />

        <Nav />

        <Home gsap={gsap} />
        <About />
        <Skills />
        <Portfolio />
        <Contact />

        <Footer />

        <Routes>
          <Route path="/work/:id" element={<Workmodal />} />
        </Routes>
      </Browser>
    </div>
  );
}

export default App;
