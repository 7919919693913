import React, { useEffect, useRef } from 'react';
import './ScrollDown.css'

// ♠ gsap
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ScrollDown = () => {
  let mouseDown = useRef(null);
  let tl2 = gsap.timeline();

  useEffect(()=>{
    tl2.fromTo(mouseDown,{
      opacity:0,
      y:-20,
    },{
        opacity:1,
        y:0,
        duration: 2,
      ease: "bounce.out",  
      repeat:-1,
      delay:3,
    });
  })

  return (
    <div className='portaScroll'>
    <svg viewBox="0 0 21 62" fill="none" xmlns="http://www.w3.org/2000/svg" className='mouseScroll' ref={(el)=>(mouseDown = el)}>
      <g clipPath="url(#clip0_43_474)">
      <path d="M10.8872 36.8362C5.37051 36.8362 0.887207 32.3529 0.887207 26.8362V10C0.887207 4.48331 5.37051 0 10.8872 0C16.4039 0 20.8872 4.48331 20.8872 10V26.8203C20.8872 32.3529 16.4039 36.8362 10.8872 36.8362ZM10.8872 3.17965C7.13522 3.17965 4.06686 6.24801 4.06686 10V26.8203C4.06686 30.5882 7.13522 33.6407 10.8872 33.6407C14.6551 33.6407 17.7076 30.5723 17.7076 26.8203V10C17.7076 6.24801 14.6551 3.17965 10.8872 3.17965Z" fill="white" className='mouseBody'/>
      <path d="M10.728 61.5739C10.2987 61.5739 9.90129 61.3991 9.59923 61.1129L1.50702 53.0048C0.886984 52.3847 0.886984 51.3832 1.50702 50.7631C2.12705 50.1431 3.12864 50.1431 3.74867 50.7631L10.728 57.7425L18.0094 50.4611C18.6294 49.841 19.631 49.841 20.2511 50.4611C20.8711 51.0811 20.8711 52.0827 20.2511 52.7027L11.8568 61.097C11.5547 61.3991 11.1573 61.5739 10.728 61.5739Z" fill="white" className='arrowDot'/>
      <path d="M10.8872 16.2003C10.0128 16.2003 9.29736 15.4849 9.29736 14.6105V9.84102C9.29736 8.96661 10.0128 8.25119 10.8872 8.25119C11.7616 8.25119 12.477 8.96661 12.477 9.84102V14.6105C12.477 15.5008 11.7616 16.2003 10.8872 16.2003Z" fill="white" className='mouseWheel'/>
      <path d="M10.8872 51.7965C10.0128 51.7965 9.29736 51.0811 9.29736 50.2067C9.29736 49.3323 10.0128 48.6169 10.8872 48.6169C11.7616 48.6169 12.477 49.3323 12.477 50.2067C12.477 51.0811 11.7616 51.7965 10.8872 51.7965Z" fill="white" className='bottomDot'/>
      <path d="M10.8872 45.6598C10.0128 45.6598 9.29736 44.9444 9.29736 44.07C9.29736 43.1956 10.0128 42.4801 10.8872 42.4801C11.7616 42.4801 12.477 43.1956 12.477 44.07C12.477 44.9444 11.7616 45.6598 10.8872 45.6598Z" fill="white" className='topDot'/>
      </g>
      <defs>
      <clipPath id="clip0_43_474">
      <rect width="20" height="61.5739" fill="white" transform="translate(0.887207)"/>
      </clipPath>
      </defs>
    </svg>
</div>
  )
}

export default ScrollDown