import React, {useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../context/LangContext.js';
import emailjs from '@emailjs/browser';

//  ♠ gsap
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';



const Contact = () => {

  const language = useContext(langContext);

  // const form = useRef();

  
 //contact__form
//  gsap.registerPlugin(ScrollTrigger);

 



  const sendEmail = (e) => {
    e.preventDefault();
    
    var confirmacion = document.getElementById('resultado');

    emailjs.sendForm('service_ul5yu1j', 'template_gzkaatm', e.target, 'ieFCGfoJnxC5TpBEU').then((result)=>{
      confirmacion.innerHTML+= `<h4 class="msg__succes">Message sent Successfully</h4>`
          setTimeout(()=>confirmacion.style.display = "none",7000);
          e.target.reset()
    }, (error) =>{
      confirmacion.innerHTML+=`<h4 class="msg__wrong">Something went Wrong<h4>`
          setTimeout(()=>confirmacion.style.display = "none", 7000)
          e.target.reset()
    });
    //email_service, email_template, e.target, public_key
  };

  return (
    <section className="container contCont" id="contact">
      <h2 className="section__title">Contact me</h2>

      <div className='contContact'>
        <div>
          <p><FormattedMessage id='contact_p1' default="Feel free to send me an inquiry, comments or propose, i response you as soon as possible." /></p>
          <p><FormattedMessage id='contact_p2' default="Or you can download my CV to know more about me and my formation from here." /></p>

          <a href={
            language.locale === "en-US"
              ? "https://tenco.com.ar/download/eng/Tenconi-Daniel-eng.pdf"
              : "https://tenco.com.ar/download/esp/Tenconi-Daniel-spa.pdf"
          }
          target="_blank"
          className="linkDownload" rel="noreferrer">
            <FormattedMessage id="contact.down" defaultMessage="Download CV" />
          </a>

          <p>
          <FormattedMessage id="contact_p3" defaultMessage="Thank you!" />
        </p>
        </div>
        
        <form action="" className='contact__form' onSubmit={sendEmail}>
          <input type="text" name="nombre" id="" placeholder='name' required/>
          <input type="email" name="email" id="" placeholder='email' required/>
          <textarea name="mensaje" id="" placeholder='Let me your message!' required></textarea>

          <div id="resultado" className="resultado"></div>
         

          <div className="contact__form--option">
            <input type="submit" value={'Send'} className="fillBtn"/>


            <a href="https://api.whatsapp.com/send?phone=4368864978118&text=Hello%20Daniel!" target="_blank" rel="noreferrer" >
              <p>
                <FormattedMessage id="contact.w" />
              </p>

              <svg width="448" height="448" viewBox="0 0 448 448" fill="none" xmlns="http://www.w3.org/2000/svg" className="contact__form--ico-what" >
                <path d="M380.9 65.1C339 23.1 283.2 0 223.9 0C101.5 0 1.9 99.6 1.9 222C1.9 261.1 12.1 299.3 31.5 333L0 448L117.7 417.1C150.1 434.8 186.6 444.1 223.8 444.1H223.9C346.2 444.1 448 344.5 448 222.1C448 162.8 422.8 107.1 380.9 65.1V65.1ZM223.9 406.7C190.7 406.7 158.2 397.8 129.9 381L123.2 377L53.4 395.3L72 327.2L67.6 320.2C49.1 290.8 39.4 256.9 39.4 222C39.4 120.3 122.2 37.5 224 37.5C273.3 37.5 319.6 56.7 354.4 91.6C389.2 126.5 410.6 172.8 410.5 222.1C410.5 323.9 325.6 406.7 223.9 406.7V406.7ZM325.1 268.5C319.6 265.7 292.3 252.3 287.2 250.5C282.1 248.6 278.4 247.7 274.7 253.3C271 258.9 260.4 271.3 257.1 275.1C253.9 278.8 250.6 279.3 245.1 276.5C212.5 260.2 191.1 247.4 169.6 210.5C163.9 200.7 175.3 201.4 185.9 180.2C187.7 176.5 186.8 173.3 185.4 170.5C184 167.7 172.9 140.4 168.3 129.3C163.8 118.5 159.2 120 155.8 119.8C152.6 119.6 148.9 119.6 145.2 119.6C141.5 119.6 135.5 121 130.4 126.5C125.3 132.1 111 145.5 111 172.8C111 200.1 130.9 226.5 133.6 230.2C136.4 233.9 172.7 289.9 228.4 314C263.6 329.2 277.4 330.5 295 327.9C305.7 326.3 327.8 314.5 332.4 301.5C337 288.5 337 277.4 335.6 275.1C334.3 272.6 330.6 271.2 325.1 268.5Z" fill="currentColor" />
              </svg>
            </a>
          </div>

          
          
        </form>

      </div>
    </section>
  );
};

export default Contact;
