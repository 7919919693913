import React, { useContext } from 'react';
import { langContext } from '../../context/LangContext.js';
import spanishFlag from './../../img/flag-spain.svg';
import usaFlag from './../../img/flag-usa.svg';

const SwitchLang = () => {
  const language = useContext(langContext);


  const defineLanguage = () => {
    let checkBtn = document.querySelector('#checkLang').checked;

    if (checkBtn === false) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="commandLanguage">
      <div>
        {language.locale === 'en-US' || language.locale === undefined || language.locale === null 
        ? <img src={usaFlag} className="flag" alt='english language'/> 
        : <img src={spanishFlag} className="flag" alt='languaje español'/> }
      </div>

      <div className="switchLang">
        <p>eng</p>
        <input
          type="checkbox"
          name="checkLang"
          id="checkLang"
          className="checkBox"
          onClick={() => language.switchLanguage(defineLanguage())}
        />
        <p>spa</p>
      </div>
    </div>
  );
};

export default SwitchLang;
