import React from 'react';
import { FormattedMessage } from 'react-intl';

import WorksPort from '../components/Portfolio/Works.jsx';

const Portfolio = () => {
  return (
    <section className='container contPort' id='portfolio'>

        <h2 className='section__title'>
          <FormattedMessage id='port.tit' defaultMessage='Portfolio' />
        </h2>

        <p><FormattedMessage id="port_p1" defaultMessage="The intersection of feelings of hate and love has left traces through my career, which over time have allowed me to reconcile with my profession, reaching the point of balance to transform feeling into design."/></p>
        
        <p><FormattedMessage id="port_p2" defaultMessage="The selected works represent my maximum expression and communication between the inflection of my own thought and the client too."/></p>

        <p className="portfolio_invite"><FormattedMessage id="port_p3" defaultMessage="Feel free to click, slide and explore"/></p>

        <div className="portfolio__grid">
        <WorksPort />
        </div>


    </section>
  )
}

export default Portfolio