import React, { useRef, useEffect } from 'react';
import './Swip.css';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// IMG
import icoJs from './../../img/js.svg';
import icoReact from './../../img/react.svg';
import icoSass from './../../img/sass_logo.svg';
import icoBoots from './../../img/bootstrap.svg';
import icoHtml from './../../img/html5.svg';
import icoCss from './../../img/css3.svg';
import icoGit from './../../img/ico_github.svg';
import icoMaterial from './../../img/material-ui.svg';
import icoFigma from './../../img/figma.svg';
import icoPs from './../../img/photoshop.svg';
import icoAi from './../../img/illustrator.svg';
import icoMongo from './../../img/mongo.svg';
import icoExpress from './../../img/expressjs.svg';

const Swip = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    // Register Swiper web component
    register();

    // Object with parameters
    const params = {
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        420: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
        1084: {
          slidesPerView: 6,
        },
        1604: {
          slidesPerView: 7,
        },
      },
      loop:true,
      mousewheelForceToAxis:true,
      navigation:true,
      pagination:true,      
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);
      

  return (
    <div className="skills__bg">

      <swiper-container 
      autoplay="true"
      speed="1200"
      ref={swiperRef}
       >
        <swiper-slide>
          <div className="technology">
            <img src={icoFigma} alt="figma" className="skills__ico" />
            <p className="toolName">Figma</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoPs} alt="photoshop" className="skills__ico" />
            <p className="toolName">Photoshop</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoAi} alt="illustrator" className="skills__ico" />
            <p className="toolName">Illustrator</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoHtml} alt="html5" className="skills__ico" />
            <p className="toolName">HTML5</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoCss} alt="css3" className="skills__ico" />
            <p className="toolName">CSS3</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoBoots} alt="bootstrap" className="skills__ico" />
            <p className="toolName">Bootstrap</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoSass} alt="scss-sass" className="skills__ico" />
            <p className="toolName">SASS</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoJs} alt="javascript" className="skills__ico" />
            <p className="toolName">JavaScript</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoReact} alt="react js" className="skills__ico" />
            <p className="toolName">React JS</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoMaterial} alt="material ui" className="skills__ico" />
            <p className="toolName">Material UI</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoMongo} alt="mongo db" className="skills__ico" />
            <p className="toolName">Mongo DB</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoExpress} alt="express js" className="skills__ico" />
            <p className="toolName">Express JS</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="technology">
            <img src={icoGit} alt="git hub" className="skills__ico" />
            <p className="toolName">Git Hub</p>
            <div className="porta__points">
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points full"></div>
              <div className="points empty"></div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      
    </div>
  );
};

export default Swip;
