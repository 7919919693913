import React, { useEffect, useRef } from 'react';
import './Logo.css';

// ♠ gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


const Logo = () => {

  return (
    <svg viewBox="0 0 2617 1440" fill="none" xmlns="http://www.w3.org/2000/svg"  className='brand' >
      <path d="M1414.2 224.4L1202.6 436L1157.1 390.5H1228.9C1242.7 390.5 1253.9 379.3 1253.9 365.5C1253.9 351.7 1242.7 340.5 1228.9 340.5H1036.4L1184.9 489C1189.8 493.9 1196.2 496.3 1202.6 496.3C1209 496.3 1215.4 493.9 1220.3 489L1414.3 295L1459.8 340.5H1393C1379.2 340.5 1368 351.7 1368 365.5C1368 379.3 1379.2 390.5 1393 390.5H1580.4L1414.2 224.4Z" fill="url(#paint0_linear_49_627)" className='iso'/>
      {/* name */}
      <path d="M685.2 1158.9C685.2 1170.8 676.4 1177.6 658.9 1177.6H626.7V1107.6H656.9C672.5 1107.6 681.5 1114.2 681.5 1125.6C681.5 1133.6 677.2 1138.9 670.8 1141.5C679.8 1143.5 685.2 1149.4 685.2 1158.9ZM634.1 1113.7V1139.1H656.4C667.6 1139.1 674.1 1134.8 674.1 1126.4C674.1 1118 667.6 1113.7 656.4 1113.7H634.1ZM677.8 1158.3C677.8 1149.2 671.1 1145.2 658.8 1145.2H634.1V1171.5H658.8C671.1 1171.5 677.8 1167.5 677.8 1158.3Z" fill="#80D8FF" className='nameBy'/>
      <path d="M773.701 1125L747.401 1183.9C743.001 1194.2 737.401 1197.5 729.901 1197.5C725.001 1197.5 720.301 1195.9 717.101 1192.7L720.401 1187.4C723.001 1190 726.201 1191.4 730.001 1191.4C734.701 1191.4 737.901 1189.2 740.901 1182.6L743.201 1177.5L719.701 1125H727.101L746.901 1169.7L766.701 1125H773.701V1125Z" fill="#80D8FF" className='nameBy'/>
      <path d="M872.601 1107.6H901.101C923.501 1107.6 938.601 1122 938.601 1142.6C938.601 1163.2 923.501 1177.6 901.101 1177.6H872.601V1107.6ZM900.701 1171.2C919.301 1171.2 931.301 1159.4 931.301 1142.6C931.301 1125.8 919.301 1114 900.701 1114H880.001V1171.2H900.701V1171.2Z" fill="#80D8FF"  className='nameBy'/>
      <path d="M1024.2 1158.9H985.201L976.801 1177.6H969.101L1001.1 1107.6H1008.4L1040.4 1177.6H1032.6L1024.2 1158.9ZM1021.5 1152.9L1004.7 1115.3L987.901 1152.9H1021.5Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1138.4 1107.6V1177.6H1132.3L1087.3 1120.8V1177.6H1079.9V1107.6H1086L1131.1 1164.4V1107.6H1138.4Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1187.9 1107.6H1195.3V1177.6H1187.9V1107.6Z" fill="#80D8FF"  className='nameBy'/>
      <path d="M1294 1171.2V1177.6H1244.5V1107.6H1292.5V1114H1251.9V1138.9H1288.1V1145.2H1251.9V1171.2H1294V1171.2Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1336.1 1107.6H1343.5V1171.2H1382.7V1177.6H1336.1V1107.6V1107.6Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1496 1114H1471.4V1107.6H1528V1114H1503.4V1177.6H1496V1114Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1617.1 1171.2V1177.6H1567.6V1107.6H1615.6V1114H1575V1138.9H1611.2V1145.2H1575V1171.2H1617.1V1171.2Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1717.7 1107.6V1177.6H1711.6L1666.6 1120.8V1177.6H1659.2V1107.6H1665.3L1710.4 1164.4V1107.6H1717.7Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1761.9 1142.6C1761.9 1122.1 1777.5 1107 1798.6 1107C1808.8 1107 1818 1110.4 1824.2 1117.2L1819.5 1121.9C1813.8 1116.1 1806.8 1113.6 1798.8 1113.6C1781.9 1113.6 1769.2 1126 1769.2 1142.6C1769.2 1159.2 1781.9 1171.6 1798.8 1171.6C1806.8 1171.6 1813.8 1169.1 1819.5 1163.2L1824.2 1167.9C1818 1174.7 1808.8 1178.2 1798.5 1178.2C1777.5 1178.2 1761.9 1163.1 1761.9 1142.6Z" fill="#80D8FF" className='nameBy'/>
      <path d="M1859.8 1142.6C1859.8 1122.2 1875.4 1107 1896.6 1107C1917.6 1107 1933.3 1122.1 1933.3 1142.6C1933.3 1163.1 1917.6 1178.2 1896.6 1178.2C1875.4 1178.2 1859.8 1163 1859.8 1142.6ZM1925.9 1142.6C1925.9 1125.9 1913.4 1113.6 1896.6 1113.6C1879.7 1113.6 1867.1 1125.9 1867.1 1142.6C1867.1 1159.3 1879.7 1171.6 1896.6 1171.6C1913.4 1171.6 1925.9 1159.3 1925.9 1142.6Z" fill="#80D8FF" className='nameBy'/>
      <path d="M2036.1 1107.6V1177.6H2030L1985 1120.8V1177.6H1977.6V1107.6H1983.7L2028.8 1164.4V1107.6H2036.1Z" fill="#80D8FF" className='nameBy'/>
      <path d="M2085.5 1107.6H2092.9V1177.6H2085.5V1107.6Z" fill="#80D8FF" className='nameBy'/>

      {/* tenco */}
      <path d="M659.2 654.2H261.4V704.2H634.2V915.9H684.2V679.2C684.2 665.4 673 654.2 659.2 654.2Z" fill="white"  className='tFont'/>
      <path d="M1368.6 830.5L1199.6 661.5C1192.4 654.3 1181.7 652.2 1172.4 656.1C1163.1 660 1157 669.1 1157 679.2V915.9H1207V739.6L1376 908.6C1380.8 913.4 1387.2 915.9 1393.7 915.9C1396.9 915.9 1400.2 915.3 1403.3 914C1412.6 910.1 1418.7 901 1418.7 890.9V654.2H1368.7V830.5H1368.6Z" fill="white" className='nFont'/>
      <path d="M1498.9 679.2V890.9C1498.9 904.7 1510.1 915.9 1523.9 915.9H1760.6V865.9H1548.9V704.2H1760.6V654.2H1523.9C1510.1 654.2 1498.9 665.4 1498.9 679.2Z" fill="white" className='cFont'/>
      <path d="M2067.6 654.2H1856C1842.2 654.2 1831 665.4 1831 679.2V890.9C1831 904.7 1842.2 915.9 1856 915.9H2067.7C2081.5 915.9 2092.7 904.7 2092.7 890.9V679.2C2092.6 665.4 2081.4 654.2 2067.6 654.2ZM2042.6 865.9H1881V704.2H2042.7V865.9H2042.6Z" fill="white" className='oFont'/>
      <path d="M1032.4 654.2H820.7C806.9 654.2 795.7 665.4 795.7 679.2V890.9C795.7 904.7 806.9 915.9 820.7 915.9H1057.4V865.9H845.7V704.2H1007.4V740H906.5C892.7 740 881.5 751.2 881.5 765C881.5 778.8 892.7 790 906.5 790H1057.3V679.2C1057.4 665.4 1046.2 654.2 1032.4 654.2Z" fill="white" className='eFont' />
      
      {/* sticks */}
      <path d="M1941.8 740C1927.9 740 1916.7 751.2 1916.7 765C1916.7 778.8 1927.9 790 1941.7 790H2355.3V740H1941.8Z" fill="white" className='oFont_stick' />
      <path d="M573.4 740H523.4V790H573.4C587.2 790 598.4 778.8 598.4 765C598.4 751.2 587.2 740 573.4 740Z" fill="white" className='tFont_stick' />
      <defs>
      <linearGradient id="paint0_linear_49_627" x1="1184.88" y1="489.078" x2="1431.92" y2="242.042" gradientUnits="userSpaceOnUse">
      <stop stopColor="#80D8FF"/>
      <stop offset="1" stopColor="#AEEA00"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo