import React from 'react';
import { FormattedMessage } from 'react-intl';
import Swip from '../components/SwiperSkills/Swip.jsx';
// import SwiperSkills from '../components/SwiperSkills/SwiperSkills.jsx';
// import icoJs from './../img/js.svg';
// import icoReact from './../img/react.svg';
// import icoSass from './../img/sass_logo.svg';
// import icoBoots from './../img/bootstrap.svg';
// import icoHtml from './../img/html5.svg';
// import icoCss from './../img/css3.svg';
// import icoGit from './../img/ico_github.svg';
// import icoMaterial from './../img/material-ui.svg';
// import icoFigma from './../img/figma.svg';
// import icoPs from './../img/photoshop.svg';
// import icoAi from './../img/illustrator.svg';
// import icoMongo from './../img/mongo.svg';
// import icoExpress from './../img/expressjs.svg';


const Skills = () => {
  return (
    <section id='skills'> 
    <div className="contSkills">
      <h2 className='section__title'><FormattedMessage id='skill.tit'/></h2>
    </div>

      <Swip />
      {/* <SwiperSkills /> */}

    </section>
  );
};

export default Skills;
