import React, { createContext, useState } from 'react';
import EnglishText from './../lang/en-US.json';
import SpanishText from './../lang/es-AR.json';

import { IntlProvider } from 'react-intl';

const langContext = createContext(); // creo el contexto con createContext y lo guardo en una variable

const LangProvider = ({ children }) => {
  // creo un proovedor que encerrará toda nuestra aplicacion

  let byDefectLocale;
  let byDefectText;
  const lang = localStorage.getItem('lang');


  if (lang || !lang) {
    byDefectLocale = lang;
    if (lang === 'es-AR') {
      byDefectText = SpanishText;
    } else if (lang === 'en-US') {
      byDefectText = EnglishText;
    } else {
      localStorage.setItem('lang', 'en-US');
      byDefectLocale = 'en-US';
      byDefectText = EnglishText;
    }
  }

  const [message, setMessage] = useState(byDefectText);
  const [locale, setLocale] = useState(byDefectLocale);

  const switchLanguage = (language) => {
    switch (language) {
      case false:
        setMessage(EnglishText);
        setLocale('en-US');
        localStorage.setItem('lang', 'en-US');
        break;

      case true:
        setMessage(SpanishText);
        setLocale('es-AR');
        localStorage.setItem('lang', 'es-AR');
        break;

      default:
        setMessage(EnglishText);
        setLocale('en-US');
        localStorage.setItem('lang', 'en-US');
    }
  };

  return (
    <langContext.Provider value={{ switchLanguage: switchLanguage, locale }}>
      <IntlProvider locale={locale} messages={message}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext }; // exporto la variable con el contexto, y, el proovedor
