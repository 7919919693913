import React, { useContext, useState } from 'react';
// import trabajos from '../../data/works-en.json'
import { WorksContext } from '../../context/WorksContext.js';
import { Link } from 'react-router-dom';
import './Works.css';
import Loading from '../Loading/Loading.jsx';
/* react tilt */
import { Tilt } from 'react-tilt';
import tiltOptions from '../../effects/Tilt.js';
//  ♠ gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const WorksPort = () => {
  // const aParse = JSON.stringify(trabajos)
  const workisContext = useContext(WorksContext);
  const { works } = workisContext;

  const tilt = tiltOptions; // tilt effect

  const [load, setLoad] = useState(false);
  setTimeout(() => {
    setLoad(true);
  }, 900);

  // ♠ gsap
  gsap.defaults({ ease: 'power3' });
  gsap.set('.work__card', {
    y: 150,
    scrollTrigger: {
      start: 'bottom bottom',
      end: 'bottom bottom',
      markers: false,
      stagger: 0,
    },
  });

  ScrollTrigger.batch('.work__card', {
    //interval: 0.1, // time window (in seconds) for batching to occur.
    //batchMax: 3,   // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: { each: 0.15, grid: [1, 5] },
        overwrite: true,
      }),
    onLeave: (batch) =>
      gsap.set(batch, { opacity: 0, y: -100, overwrite: true }),
    onEnterBack: (batch) =>
      gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
    onLeaveBack: (batch) =>
      gsap.set(batch, { opacity: 0, y: 100, overwrite: true }),
    // you can also define things like start, end, etc.
  });

  // when ScrollTrigger does a refresh(), it maps all the positioning data which
  // factors in transforms, but in this example we're initially setting all the ".box"
  // elements to a "y" of 100 solely for the animation in which would throw off the normal
  // positioning, so we use a "refreshInit" listener to reset the y temporarily. When we
  // return a gsap.set() in the listener, it'll automatically revert it after the refresh()!
  ScrollTrigger.addEventListener('refreshInit', () =>
    gsap.set('.work__card', { y: 200 })
  );

  return (
    <div className="works__grid">
      {works.map((data) => {
        return (
          <Tilt options={tilt} key={data.id}>
            <Link to={`/work/${data.id}`}>
              <div key={data.id} className="work__card">
                <p className="work__category">{data.cat}</p>

                <div className="work__image--container">
                  {load ? (
                    <img
                      src={data.img}
                      alt={data.tit}
                      loading="lazy"
                      className="work__image"
                    />
                  ) : (
                    <Loading />
                  )}
                </div>

                <div className="work__info">
                  <h3 className="work__tit">{data.tit}</h3>
                  <p className="work__description">{data.desc}</p>
                </div>
              </div>
            </Link>
          </Tilt>
        );
      })}
    </div>
  );
};

export default WorksPort;
