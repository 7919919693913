import React, { createContext, useState } from 'react';

export const WorksContext = createContext();

export const WorksProvider = ({ children }) => {
  const [works, setWorks] = useState([
    {
      id: '014',
      cat: 'web design',
      tit: 'NewAgro Coin',
      desc: 'Website layout.',
      det: 'Website layout and responsive design for New  Agro Coin The design was provided by the client: Pampa Nuestra.',
      tec: 'HTML, CSS3 technologies have been used for the layout; and adapted to different screen formats. JavaScript has been used for animation and cards behaviors',
      img: 'https://tenco.com.ar/img/portfolio/newagro-coin.jpg',
      media: 'https://tenco.com.ar/media/newagro-coin.mp4',
      url: 'https://newagrocoin.com/'
    },

    {
      id: '009',
      cat: 'web design',
      tit: 'AUSA',
      desc: 'Website layout.',
      det: 'Website layout and responsive design for AUSA (Urban Highways). The design was provided by the client: Agencia Capitan.',
      tec: 'HTML5 and CSS3 technologies have been used for the layout; and adapted to different screen formats. JavaScript has been used for functionality on different screens.',
      img: 'https://tenco.com.ar/img/portfolio/ausa.jpg',
      media: 'https://tenco.com.ar/media/ausa.mp4',
      url: 'https://www.ausa.com.ar/'
    },

    {
      id: '007',
      cat: 'web design',
      tit: 'Balvanera',
      desc: 'Website layout.',
      det: 'Layout with responsive design made for Balvanera. This is a one page work where the main objective was to adapt "scroll effects" and simple animations. The design was provided out by the Capitan Agency.',
      tec: 'HTML5 and CSS3 technologies have been used for the layout; and it was adapted to different screen formats. JavaScript has been used for scroll effects and animations.',
      img: 'https://tenco.com.ar/img/portfolio/balvanera.jpg',
      media: 'https://tenco.com.ar/media/balvanera.mp4',
      url: 'https://balvanera.com/'
    },

    {
      id: '011',
      cat: 'web design',
      tit: 'Jóvenes DAS',
      desc: 'Layout with responsive design made for "Distribuidora del Sur".',
      det: 'Landing page whose main objective was to adapt "scroll effects" and animations. The design was done by Ju Kers.',
      tec: 'HTML5 and CSS3 technologies have been used for the layout; and adapted to different screen formats. JavaScript has been used for scrolling effects and animations. PHP technology has been used to send form data by mail.',
      img: 'https://tenco.com.ar/img/portfolio/jovenesdas.jpg',
      media: 'https://tenco.com.ar/media/jovenes-das.mp4',
      url: 'https://distribuidoradelsur.com/jovenesdas/'
    },

    {
      id: '012',
      cat: 'web design',
      tit: 'Priceless Cities',
      desc: 'Web design and layout of a portal of "experiences" for Priceless Cities.',
      det: 'The customer "Grupo Acento" requested a portal for users of a popular credit card, who could hire "exclusive experiences" that would be provided through the website.',
      tec: 'HTML5 and CSS3 technologies have been used for the layout; and adapted to different screen formats. Frameworks and JavaScript for functionality and dynamism in carousels were used.',
      img: 'https://tenco.com.ar/img/portfolio/mc-priceless.jpg',
      media: 'https://tenco.com.ar/media/priceless-mastercard.mp4'
    },

    {
      id: '013',
      cat: 'web design',
      tit: 'Expo Tobin',
      desc: 'Responsive Website design made for Tobin "Genetically Superior".',
      det: 'The purpose of the site is to show the differents seeds they sell as well as their characteristics, and news. The design was provided by JuKers Concept.',
      tec: 'This work has been done with HTML5 and CSS3 technologies for layout and style for adaptation to screens of different formats, and JavaScript for effects.',
      img: 'https://tenco.com.ar/img/portfolio/tobin.jpg',
      media: 'https://tenco.com.ar/media/tobin.mp4',
      url: 'https://tobin.com.ar/'
    },

    {
      id: '001',
      cat: 'web & graphic design',
      tit: 'Jajám',
      desc: 'Photography, 3D modeling, animation, design and layout of a website.',
      det: 'The purpose of the Website was to turn around on "who we are", "music", "dates and shows", and other options. Although today it is obsolete, I expose this work because of the number of tools used and it represents a break in my career.',
      tec: 'Adobe Flash, Autodesk 3D Max package has been used.',
      img: 'https://tenco.com.ar/img/portfolio/jajam.jpg',
      media: 'https://tenco.com.ar/media/jajam.mp4'
    },

    {
      id: '002',
      cat: 'graphic design',
      tit: 'Kamapua',
      desc: 'Image design made for a store specializing in sports preparation and conditioning.',
      det: 'The typographic choice, the selection of colors and the aesthetics are due to the meaning of the name; it refers to a semi-human god who lived in the Hawaiian Islands.',
      img: 'https://tenco.com.ar/img/portfolio/kamapua.jpg',
      brand: 'https://tenco.com.ar/img/portfolio/kamapua.svg',
      url: 'https://www.instagram.com/p/BjL4CZfhfuL/',          
      design:{
        desc_p1:'Kamapua is a triathlon store. On this opportunity I had to design the brand. This category includes running, swimming and cycling.',
        desc_p2:'This store has relevance  and specific knowledge in the cycling world, for this reason the brand makes reference to it.',
        desc_p3:'The typographic choice, the selection of colors and the aesthetics are related to the meaning of the name; refers to a semi-human god who lived in the Hawaiian Islands.',
        brand:{
          grid:'https://tenco.com.ar/img/portfolio/gd_kamapua/kamapua-partial_grid.svg',
          brand:'https://tenco.com.ar/img/portfolio/gd_kamapua/kamapua.svg',
          brand_hz:'https://tenco.com.ar/img/portfolio/gd_kamapua/kamapua-hz.svg'
        },
        typo:{
          det: '“Team America Condensed” was chosen as the main font after searching for one that gather the criteria of dynamism and strength, reflecting two attributes typical of these sports.',         
          img:'https://tenco.com.ar/img/portfolio/gd_kamapua/kamapua-typo.svg',  
        },
        color: {
          det:'For this color selection I looked for colors that represented the dynamism of the sport through its contrast, so I decided to use the colors of the Hawaiian flag, red and blue, because Kamapua refers to a demigod corresponding to the mythology of this country.',
          color_1:['#0089B9', 'R0 G137 B185', 'C100 M30 Y15 K0'],
          color_2:['#DA202F','R219 G32 B47','C10 M100 Y92 K0'],
          color_3: null,
        }
      }
    },

    {
      id: '003',
      cat: 'graphic design',
      tit: 'Deco Plantin',
      desc: 'Creation of institutional identity.',
      det: 'Image design made for a store specializing in decoration and all kinds of plants. The aesthetics was achieved by merging the decoration with the modularity offered by plants.',
      img: 'https://tenco.com.ar/img/portfolio/deco.jpg',
      brand: 'https://tenco.com.ar/img/portfolio/decoplantin.svg',
      url: 'https://www.decoplantin.com.ar/',
      design:{
        desc_p1:'Deco-Plantin is an ecommerce dedicated to the sale of plants for decoration,.',
        desc_p2:'',
        desc_p3:'',
        brand:{
          brand:'https://tenco.com.ar/img/portfolio/gd_deco-plantin/deco_plantin.svg',
          brand_hz:'https://tenco.com.ar/img/portfolio/gd_deco-plantin/deco_plantin-hz.svg',
        },
        typo:{
          det: '“Misses” from callygraphics families with organics and modulated traces with a little intervention, and "Corbel", a geometric typography belonging to the sans-serif families were chosen to create the brand. The combination of this two types has generated a perfect balance between nature and decoration.',         
          img:'https://tenco.com.ar/img/portfolio/gd_deco-plantin/deco_plantin-typo_inv.svg',  
        },
        color: {
          det:'The selection of colors was conditioned by plants and nature because the main products that refer to it. Green and brown were selected for symbolism with nature.',
          color_1:['#006838', 'R0 G103 B55', 'C90 M30 Y95 K30'],
          color_2:['#4A2500','R73 G35 B0','C0 M50 Y100 K85'],
          color_3: null
        }
      }
    },

    {
      id: '005',
      cat: 'web & graphic design',
      tit: 'Universo de Mascotas',
      desc: 'Company logo, newsletter and website.',
      det: 'Business image design. Universo de Mascotas is a non-profit NGO located in the province of Río Negro (Argentina) dedicated to the "prayer" and "collaboration" of pets. The typography, colors and general aesthetic chosen refer to ancient Egypt, where animals were revered and their status was superior compared to humans.',
      img: 'https://tenco.com.ar/img/portfolio/universo.jpg',
      brand: 'https://tenco.com.ar/img/portfolio/universo.svg',
      design:{
        desc_p1:'This brand resulted as a result of the work and feedback with the client, it was his desire that the brand be linked to Egyptian mythology, to Anubis, guardian of souls. ',
        desc_p2:'For this reason, the elements that compose it were selected: the dog representing Anubis, the triangular containing figure in relation to the pyramids and the sun, Aton.',
        desc_p3:'',
        brand:{
          grid:null,
          brand:'https://tenco.com.ar/img/portfolio/gd_universo/universo-brand.svg',
          brand_hz:null
        },
        typo:{
          det: '“Lithos Pro” was the selected typography because its strokes presents a little modulation are clean, and it have certain reminiscence to egyptian hieroglyphic.',         
          img:'https://tenco.com.ar/img/portfolio/gd_universo/universo-typo.svg',  
        },
        color: {
          det:'This chromatic palette derived of the items that compose the visual identity.',
          color_1:['#101718', 'R16 G23 B24', 'C77 M66 Y65 K79'],
          color_2:['#FDC314','R253 G195 B20','C1 M24 Y98 K0'],
          color_3:['#AA1F27','R170 G31 B39','C22 M99 Y95 K15']
        }
      }
    },

    {
      id: '006',
      cat: 'graphic design',
      tit: 'Fogones Aiken',
      desc: 'Image design and corporate identity. Content creation through promotional and marketing images.',
      det: 'This corporate image was created for a bioethanol stove sales venture; one of the meanings attributed to the name Aiken is "meeting place"',
      tec: '',
      img: 'https://tenco.com.ar/img/portfolio/fogones.jpg',
      brand: 'https://tenco.com.ar/img/portfolio/fogones.svg',
      url: 'https://www.instagram.com/fogonesaiken/',
      design:{
        desc_p1:'"Fogones Aiken" is a online store that sells decorative and heating stoves. This company does not work with ecommerce, it only sold products through instagram.',
        desc_p2: '',
        desc_p3: '',


        brand:{
          grid:null,
          brand:'https://tenco.com.ar/img/portfolio/gd_fogones-aiken/aiken-brand.svg',
          brand_hz: null
        },
        typo:{
          det: '“Amatic” was chosen as main font for this brand. I chose this calligraphic and sans-serif font because its strokes are reminiscent of the strokes made on the ground with a stick or the finger around a bonfire.',         
          img:'https://tenco.com.ar/img/portfolio/gd_fogones-aiken/aiken-typo.svg',  
        },
        color: {
          det:'This selection of colors was easy to choose because I sought to reinforce the main idea of a campfire, that is why the colors are the most representative for the fire.',
          color_1:['#404041', 'R64 G64 B65', 'C68 M61 Y59 K46'],
          color_2:['#F4C625','R244 G198 B37','C5 M21 Y96 K0'],
          color_3:['#BC291F','R188 G41 B31','C19 M97 Y100 K9']
        }
      }
    },

    {
      id: '010',
      cat: 'web design',
      tit: 'Camarín de las Musas',
      desc: 'Website design and layout.',
      det: 'Site design and web layout with responsive design. The motivation of this site was to exhibit and virtually market movies, plays, art exhibitions, among other categories. To carry out this work, technologies such as HTML5 and CSS3 have been used for layout and styling with their respective adaptations to different screen formats.',
      tec: 'HTML5 and CSS3 technologies have been used for the layout; and it was adapted to different screen formats.',
      img: 'https://tenco.com.ar/img/portfolio/camarin.jpg',
      media: 'https://tenco.com.ar/media/camarin.mp4',
      url: 'https://www.elcamarindelasmusas.com/'
    },

    {
      id: '008',
      cat: 'web design',
      tit: 'AATTVAC',
      desc: 'Reorganization and layout of pre-existing website.',
      det: 'Content and information were organized and systematized.',
      tec: 'HTML5, CSS3 and JavaScript technologies have been used for the layout.',
      img: 'https://tenco.com.ar/img/portfolio/aattvac.jpg',
      media: 'https://tenco.com.ar/media/aattvac.mp4',
      url: 'https://www.aattvac.org.ar/'
    },
  ]);

  return (
    <WorksContext.Provider value={{ works, setWorks}}>
      {children}
    </WorksContext.Provider>
  );
};
